<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Buscador de documentos</h2>
        </div>

        <!-- Formulario de busqueda -->
        <div class="card">
            <div class="card-content">
                <form @submit.prevent="handleSubmit">
                    <div class="columns">
                        <div class="column">
                            <b-field
                                label="DNI / Nombre / Apellido"
                                label-position="on-border"
                            >
                                <b-input
                                    v-model="form.trabajador"
                                    name="empleado"
                                    icon-right="close-circle"
                                    icon-right-clickable
                                    @icon-right-click="form.trabajador = ''"
                                    autocomplete="off"
                                ></b-input>
                            </b-field>
                            <b-field
                                label="Tipo de Documento"
                                label-position="on-border"
                            >
                                <b-taginput
                                    v-model="form.tipos_documentos"
                                    :data="filteredTags.tipos"
                                    autocomplete
                                    open-on-focus
                                    attached
                                    field="descripcion"
                                    icon="label"
                                    type="is-primary is-light"
                                    @typing="getFilteredTagsTipos"
                                >
                                </b-taginput>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field
                                label="Periodo del documento"
                                label-position="on-border"
                            >
                                <b-datepicker
                                    type="month"
                                    icon="calendar-today"
                                    v-model="form.periodos"
                                    :mobile-native="false"
                                    range
                                >
                                </b-datepicker>
                            </b-field>
                            <b-field
                                label="Estado del Documento"
                                label-position="on-border"
                            >
                                <b-taginput
                                    v-model="form.estados_documentos"
                                    :data="filteredTags.estados"
                                    autocomplete
                                    open-on-focus
                                    attached
                                    field="descripcion"
                                    icon="label"
                                    type="is-primary is-light"
                                    @typing="getFilteredTagsEstados"
                                >
                                </b-taginput>
                            </b-field>
                        </div>
                    </div>
                    <div class="is-flex is-justify-content-center">
                        <b-button
                            type="is-primary"
                            native-type="submit"
                            :loading="isLoadingDocumentos"
                        >
                            Buscar
                        </b-button>
                    </div>
                </form>
            </div>
        </div>
        <br /><br />
        <!-- Resultados de la busqueda -->
        <tabla-busqueda :filters="filters" :pagination="pagination" />
    </div>
</template>

<script>
import moment from 'moment';

import TablaBusqueda from '../../../components/Documentos/TablaBusqueda.vue';
import Message from '@/utils/Message';

export default {
    components: {
        TablaBusqueda,
    },
    data() {
        return {
            isLoading: {
                buscar: false,
            },
            form: {
                trabajador: '',
                tipos_documentos: [],
                estados_documentos: [],
                periodos: [],
            },
            filteredTags: {
                estados: [],
                tipos: [],
            },
            pagination: {
                per_page: 10,
                page: 1,
            },
        };
    },
    computed: {
        tiposDocumentos() {
            return this.$store.state.tiposDocumentos.data;
        },
        estadosDocumentos() {
            return this.$store.state.estados.data;
        },
        isLoadingDocumentos() {
            return this.$store.state.documentos.isLoading;
        },
        filters() {
            return {
                ...this.form,
                periodos: this.form.periodos.map((periodo) =>
                    moment(periodo).format('YYYY-MM-DD').toString()
                ),
                tipos_documentos: this.form.tipos_documentos.map(
                    (tipo) => tipo.descripcion
                ),
                estados_documentos: this.form.estados_documentos.map(
                    (estado) => estado.descripcion
                ),
            };
        },
    },
    async mounted() {
        await this.$store.dispatch('tiposDocumentos/get');
        await this.$store.dispatch('estados/get', 'documentos');

        this.getFilteredTagsTipos();
        this.getFilteredTagsEstados();

        await this.handleSubmit();
    },
    methods: {
        getFilteredTagsTipos(text = '') {
            this.filteredTags.tipos = this.tiposDocumentos.filter((option) => {
                return (
                    option.descripcion
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                );
            });
        },
        getFilteredTagsEstados(text = '') {
            this.filteredTags.estados = this.estadosDocumentos.filter(
                (option) => {
                    return (
                        option.descripcion
                            .toString()
                            .toLowerCase()
                            .indexOf(text.toLowerCase()) >= 0
                    );
                }
            );
        },
        async handleSubmit() {
            try {
                this.$store.dispatch('documentos/setLoading', true);

                const res = await this.$store.dispatch('documentos/get', {
                    ...this.filters,
                    ...this.pagination,
                });
            } catch (err) {
                Message.showErrorMessage(this, err.message);
            } finally {
                this.$store.dispatch('documentos/setLoading', false);
            }
        },
    },
};
</script>

<style>
</style>
